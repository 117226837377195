.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;

  .tile{
    //height: 100%;
  }

  &.useSlider {
    display: block;
    .tile{
      height: 100%;
    }
    .slick-prev:before, .slick-next:before{
      color: red;
    }
    .slick-prev{
      left: 20px;
      @include media-breakpoint-up(xl) { // 576px and up
        left: -26px;
      }
    }
    .slick-next{
      right: 20px;
      @include media-breakpoint-up(xl) { // 576px and up
        right: -26px;
      }
    }
    .tile {
      max-width: 100%;
    }
  }
}

.pageTile__content {

  &:hover,
  &:focus {
    text-decoration: none;
    // .pageTile_details:before {
    //   background: $primary-color;
    // }
    .pageTile_details {
      background: $color-blue;
      background-image: url(/assets/page-tile-background-hover.png);
    }

    .pageTile__title,
    .pageTile_desc {
      color: white;
    }
    
    .tile__bg{
      transform: scale(1.02);
    }

  }
}

.pageTile__title {
  font-family: $family-header;
  //font-size: 1.5rem;
  font-size: rem(36, $font-size-desktop);
  text-transform: uppercase;
  font-weight: $weight-black;
  line-height: 1;
  margin: 0.5rem 0;
  color: $color-blue;
  text-align: center;
}

.pageTile_desc {
  line-height: 1.3;
  // font-size: 0.9rem;
  font-size: rem(18, $font-size-desktop);
  text-align: center;
  color: $font-color;
}

.pageTile_details {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  padding: 1.5rem 0.8rem;
  //text-align: left;
  background-color: white;
  border-bottom: 4px solid $color-red;
  background-image: url(/assets/page-tile-background.png);
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  transition: all .2s ease-in-out;
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(to bottom left, rgba(#fff, 0.3), rgba(#fff, 0));
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}
